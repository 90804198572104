exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-drivers-tsx": () => import("./../../../src/pages/drivers.tsx" /* webpackChunkName: "component---src-pages-drivers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-parents-tsx": () => import("./../../../src/pages/parents.tsx" /* webpackChunkName: "component---src-pages-parents-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "slice---src-components-about-us-about-us-tsx": () => import("./../../../src/components/about-us/AboutUs.tsx" /* webpackChunkName: "slice---src-components-about-us-about-us-tsx" */),
  "slice---src-components-drivers-drivers-tsx": () => import("./../../../src/components/drivers/Drivers.tsx" /* webpackChunkName: "slice---src-components-drivers-drivers-tsx" */),
  "slice---src-components-drivers-page-drivers-form-tsx": () => import("./../../../src/components/driversPage/DriversForm.tsx" /* webpackChunkName: "slice---src-components-drivers-page-drivers-form-tsx" */),
  "slice---src-components-drivers-page-hero-drivers-hero-tsx": () => import("./../../../src/components/driversPage/hero/DriversHero.tsx" /* webpackChunkName: "slice---src-components-drivers-page-hero-drivers-hero-tsx" */),
  "slice---src-components-hero-hero-tsx": () => import("./../../../src/components/hero/Hero.tsx" /* webpackChunkName: "slice---src-components-hero-hero-tsx" */),
  "slice---src-components-join-our-team-join-our-team-tsx": () => import("./../../../src/components/join-our-team/JoinOurTeam.tsx" /* webpackChunkName: "slice---src-components-join-our-team-join-our-team-tsx" */),
  "slice---src-components-mission-mission-tsx": () => import("./../../../src/components/mission/Mission.tsx" /* webpackChunkName: "slice---src-components-mission-mission-tsx" */),
  "slice---src-components-parents-page-hero-parents-hero-tsx": () => import("./../../../src/components/parentsPage/hero/ParentsHero.tsx" /* webpackChunkName: "slice---src-components-parents-page-hero-parents-hero-tsx" */),
  "slice---src-components-partners-how-it-works-tsx": () => import("./../../../src/components/partners/HowItWorks.tsx" /* webpackChunkName: "slice---src-components-partners-how-it-works-tsx" */),
  "slice---src-components-privacy-policy-privacy-policy-tsx": () => import("./../../../src/components/privacy-policy/PrivacyPolicy.tsx" /* webpackChunkName: "slice---src-components-privacy-policy-privacy-policy-tsx" */),
  "slice---src-components-reviews-reviews-tsx": () => import("./../../../src/components/reviews/Reviews.tsx" /* webpackChunkName: "slice---src-components-reviews-reviews-tsx" */),
  "slice---src-components-shared-cookie-tsx": () => import("./../../../src/components/shared/Cookie.tsx" /* webpackChunkName: "slice---src-components-shared-cookie-tsx" */),
  "slice---src-components-shared-form-tsx": () => import("./../../../src/components/shared/Form.tsx" /* webpackChunkName: "slice---src-components-shared-form-tsx" */),
  "slice---src-components-shared-mobile-store-tsx": () => import("./../../../src/components/shared/MobileStore.tsx" /* webpackChunkName: "slice---src-components-shared-mobile-store-tsx" */),
  "slice---src-components-strong-points-strong-points-tsx": () => import("./../../../src/components/strong-points/StrongPoints.tsx" /* webpackChunkName: "slice---src-components-strong-points-strong-points-tsx" */),
  "slice---src-components-why-us-why-us-tsx": () => import("./../../../src/components/why-us/WhyUs.tsx" /* webpackChunkName: "slice---src-components-why-us-why-us-tsx" */),
  "slice---src-layout-footer-lowe-footer-tsx": () => import("./../../../src/layout/Footer/LoweFooter.tsx" /* webpackChunkName: "slice---src-layout-footer-lowe-footer-tsx" */),
  "slice---src-layout-footer-upper-footer-tsx": () => import("./../../../src/layout/Footer/UpperFooter.tsx" /* webpackChunkName: "slice---src-layout-footer-upper-footer-tsx" */),
  "slice---src-layout-header-tsx": () => import("./../../../src/layout/Header.tsx" /* webpackChunkName: "slice---src-layout-header-tsx" */)
}

